<template>
  <SatuanKpiForm mode="Tambah" module="Satuan KPI"> </SatuanKpiForm>
</template>

<script>
import SatuanKpiForm from './form';

const SatuanKpiAdd = {
  name: 'SatuanKpiAdd',
  components: { SatuanKpiForm },
};

export default SatuanKpiAdd;
</script>
